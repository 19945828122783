import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import {
  ButtonRadioGroup,
  DropBoxPicker,
  FileDropZone,
  Form,
  GoogleDrivePicker,
  ModalWindow
} from "lib/common";
import { FiFileText } from "react-icons/fi";
import {
  FormCheckbox,
  FormGroup
} from "shards-react";
import { checkDocumentsFetching } from "selectors/documents";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import EditDocumentWindowFooter from "./lib/EditDocumentWindowFooter";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";
import classNames from "classnames";

const {
  DOCUMENT_TYPES: { INVOICE, BILL, CREDIT_NOTE, RECEIPT, SALES_RECEIPT },
  DOCUMENT_PAYMENT_TYPES: { BUY, SELL }
} = DataConstants;

const FILE_DROP_ALLOWED_EXTENSIONS = Object.values(Constants.DOCUMENT_FILE_TYPES).reduce(
  (aggregator, { extensions }) => ([...aggregator, ...extensions]), []
);

const FILE_DROP_ALLOWED_TYPE = Object.values(Constants.DOCUMENT_FILE_TYPES).reduce(
  (aggregator, { extensions, mimeType }) => ([...aggregator, ...extensions, mimeType]), []
);

const AddDocumentWindow = ({ onClose }) => {
  const fetchingData = useSelector(checkDocumentsFetching);

  const { messages, uiTexts } = useSelector(getTextsData);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const [type, setType] = useState("");

  const [paymentType, setPaymentType] = useState("");

  const [files, setFiles] = useState(null);

  const [merge, setMerge] = useState(false);

  const uniqueId = useMemo(() => uuid(), []);

  const footerProps = useMemo(() => {
    return {
      okButtonText: files?.length
        ? Utils.replaceTextVars(
          merge ? uiTexts.mergeCountDocuments : uiTexts.importCountDocuments,
          { count: files.length }
        )
        : (merge ? uiTexts.mergeDocuments : uiTexts.importDocuments),
      disabledButtons: fetchingData,
      disabledOkButton: !files || (merge && files.length === 1)
    };
  }, [fetchingData, files, merge, uiTexts]);

  const typeOptions = useMemo(() => {
    return [
      { value: "", label: uiTexts.auto },
      { value: INVOICE, label: uiTexts.invoice },
      { value: BILL, label: uiTexts.bill },
      { value: RECEIPT, label: uiTexts.receipt },
      { value: CREDIT_NOTE, label: uiTexts.creditNote }
    ];
  }, [uiTexts]);

  const closeWindow = useCallback(async(result) => {
    const documentResult = { merge, files, type, paymentType };

    await onClose(...(result ? [documentResult, result.toReport, result.toExclude] : []));
  }, [merge, paymentType, type, files, onClose]);

  const handleClose = useCallback((result) => {
    closeWindow(result);
  }, [closeWindow]);

  const handleFormSubmit = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleTypeTabChange = useCallback((value) => {
    setType(value === BILL ? INVOICE : value);
    setPaymentType(
      (value === INVOICE || value === SALES_RECEIPT) ? SELL
        : (
          (value === BILL || value === RECEIPT) ? BUY : ""
        )
    );
  }, []);

  const handleFileDropZoneDrop = useCallback((value, fileRejections) => {
    if (value.length) setFiles(value);
    else if (fileRejections.length) setFiles(null);
  }, []);

  const handleGoogleDrivePickerFilesPicked = useCallback((value, oAuthToken) => {
    onClose({
      cloudService: "gdrive",
      oAuthToken,
      googleDocs: value,
      type,
      paymentType
    });
  }, [onClose, paymentType, type]);

  const handleDropBoxPickerFilesPicked = useCallback((value) => {
    onClose({
      cloudService: "dropbox",
      filesLinks: value,
      type,
      paymentType
    });
  }, [onClose, paymentType, type]);

  const handleMergeCheckboxChange = useCallback(() => {
    setMerge((prev) => !prev);
  }, []);

  return (
    <ModalWindow
      fullScreen
      key={uniqueId}
      className={Css.modalWindow}
      bodyClassName={Css.modalBody}
      modalClassName={classNames(
        Css.editDocumentWindowContainer,
        "mode-default",
        uniqueId
      )}
      config={{
        confirm: true,
        headerText: uiTexts.importDocuments
      }}
      iconComponent={FiFileText}
      headerWrapperProps={{ className: classNames(Css.modalHeaderWrapper, CommonCss.zIndexA) }}
      footerComponent={EditDocumentWindowFooter}
      footerProps={footerProps}
      footerWrapperProps={{ className: classNames(Css.modalFooterWrapper, CommonCss.zIndexA) }}
      onClose={handleClose}>
      <Form className={Css.form} onSubmit={handleFormSubmit}>
        <FormGroup row className={CommonCss.flexCenter}>
          <ButtonRadioGroup
            outline
            size="sm"
            theme="light"
            initialValue={type}
            options={typeOptions}
            onChange={handleTypeTabChange} />
        </FormGroup>
        <FormGroup row>
          <FileDropZone
            extensions={FILE_DROP_ALLOWED_EXTENSIONS}
            accept={FILE_DROP_ALLOWED_TYPE}
            onDrop={handleFileDropZoneDrop} />
        </FormGroup>
        {!Utils.checkIsTouchDevice() && <>
          <FormGroup row className={CommonCss.flexCenter} >
            <div>{messages.importFromCloudStorage}:</div>
          </FormGroup>
          <FormGroup row className={CommonCss.flexCenter} >
            <div className={Css.cloudDocumentsPickers}>
              <GoogleDrivePicker onFilesPicked={handleGoogleDrivePickerFilesPicked} />
              <DropBoxPicker onFilesPicked={handleDropBoxPickerFilesPicked} />
            </div>
          </FormGroup>
        </>}
        {selectedBusinessData.meta.emailAddress && (
          <FormGroup row className={classNames(Css.autoImportEmailGroup, CommonCss.flexCenter)} >
            <div>{messages.autoImportDocuments}</div>
            <div><b>{selectedBusinessData.meta.emailAddress}</b></div>
          </FormGroup>
        )}
        <FormGroup row className={classNames(CommonCss.flexCenter, Css.merge)}>
          <FormCheckbox
            toggle
            checked={merge}
            onChange={handleMergeCheckboxChange} />
          <span>{messages.mergeDocumentsCheckbox}</span>
        </FormGroup>
      </Form>
    </ModalWindow>
  );
};

export default React.memo(AddDocumentWindow);
