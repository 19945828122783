import Css from "./style.module.scss";

import * as Yup from "yup";
import { Button, Col, FormGroup, Row } from "shards-react";
import { FiArrowDown, FiBriefcase, FiSearch, FiUser, FiUserX } from "react-icons/fi";
import { Preloader } from "lib/common";
import { checkContactsFetching } from "selectors/contacts";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useContactBlock } from "hooks";
import { useSelector } from "react-redux";
import DataConstants from "const/DataConstants";
import React, { useCallback, useEffect, useMemo } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().trim().required(),
  type: Yup.string().required(),
  countryCode: Yup.string(),
  idNumber: Yup.string().trim(),
  vatId: Yup.string().trim(),
  phone: Yup.string().trim(),
  email: Yup.string().trim().email(),
  state: Yup.string().trim(),
  city: Yup.string().trim(),
  street: Yup.string().trim(),
  zipCode: Yup.string().trim()
});

const EditDocumentWindowContactBlock = ({
  vendorId,
  disabled,
  prefillData,
  documentFrozen,
  onContactUnlink,
  onDataChange
}) => {
  const fetchingData = useSelector(checkContactsFetching);

  const { messages, uiTexts } = useSelector(getTextsData);

  const { id: selectedBusinessId, extraData: { integrationService } = {} } = useSelector(getSelectedBusinessData);

  const { state, ui, actions: { reset } } = useContactBlock({
    vendorId,
    prefillData,
    disabled,
    disabledSubTypeInput: true
  });

  const formValid = useMemo(() => {
    return VALIDATION_SCHEMA.isValidSync(state) && (!integrationService || state.subType);
  }, [integrationService, state]);

  const handleUnlinkContactButtonClick = useCallback(() => {
    reset();
    onContactUnlink();
  }, [reset, onContactUnlink]);

  const handleFindContactButtonClick = useCallback(() => {
    window.open(`/${selectedBusinessId + UiRoutes.CONTACTS}/?${Utils.objectToQueryString({ text: state.name })}`);
  }, [state.name, selectedBusinessId]);

  useEffect(() => {
    if (vendorId || fetchingData) return;
    if (formValid) onDataChange(state);
    else onDataChange(null);
  }, [state, vendorId, formValid, fetchingData, onDataChange]);

  return (
    <FormGroup row className={Css.editDocumentWindowContactBlock}>
      {fetchingData
        ? <Preloader />
        : <>
          {vendorId
            ? <>
              <Row form className={Css.contactNameRow}>
                <Col>
                  <div>
                    {state.type === DataConstants.CONTACT_TYPES.LEGAL_PERSON ? <FiBriefcase /> : <FiUser />}
                    <span>{state.name}</span>
                  </div>
                </Col>
              </Row>
              {ui.warningMessage && <>
                <div data-placeholder />
                <Row form className={Css.messageRow}>
                  <Col><div>{ui.warningMessage}</div></Col>
                </Row>
              </>}
              <div data-placeholder />
              <Row form className={Css.unlinkButtonRow}>
                <Col>
                  <Button
                    outline
                    theme="danger"
                    className={Css.unlinkButton}
                    disabled={documentFrozen}
                    onClick={handleUnlinkContactButtonClick}>
                    <FiUserX />
                    <span>{uiTexts.unlinkContact}</span>
                  </Button>
                </Col>
              </Row>
              <div data-placeholder />
              <Row form className={Css.messageRow}>
                <Col>
                  <div className={Css.findContactMessage}>
                    <span>{messages.editDocumentFindContact}</span>
                    <FiSearch onClick={handleFindContactButtonClick} />
                  </div>
                </Col>
              </Row>
            </>
            : <>
              <Row form><Col>{ui.prefillInputGroup}</Col></Row>
              <Row form className={Css.messageRow}>
                <Col>
                  <div><span>{`* ${messages.prefillContactData}`}</span></div>
                </Col>
              </Row>
              <div data-placeholder />
              <Row form className={Css.messageRow}>
                <Col><div><span>{messages.enterDataManually}</span><FiArrowDown /></div></Col>
              </Row>
              <div data-placeholder />
            </>}
          <Row form><Col>{ui.typeSelectGroup}</Col></Row>
          <Row form><Col>{ui.nameInputGroup}</Col></Row>
          <div data-placeholder />
          <Row form><Col>{ui.idNumberInputGroup}</Col></Row>
          <Row form><Col>{ui.vatIdInputGroup}</Col></Row>
          <div data-placeholder />
          <Row form><Col>{ui.countrySelectGroup}</Col></Row>
          <Row form><Col>{ui.stateSelectGroup}</Col></Row>
          <Row form><Col>{ui.cityInputGroup}</Col></Row>
          <Row form><Col>{ui.streetInputGroup}</Col></Row>
          <Row form><Col>{ui.zipCodeInputGroup}</Col></Row>
          <div data-placeholder />
          <Row form><Col>{ui.phoneInputGroup}</Col></Row>
          <Row form><Col>{ui.emailInputGroup}</Col></Row>
        </>}
    </FormGroup>
  );
};

export default React.memo(EditDocumentWindowContactBlock);
