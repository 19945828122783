import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { ActionsButtons, Card, Page, Preloader } from "lib/common";
import { Button, CardBody, CardHeader } from "shards-react";
import { FiAtSign, FiFileText, FiLoader, FiPlusCircle } from "react-icons/fi";
import { bind } from "decko";
import { checkDocumentsFetching, getDocumentsData, getDocumentsStats, getUploadingDocumentsCount } from "selectors/documents";
import {
  checkMatchesFetching,
  getSelectedBusinessBookCloseDate,
  getSelectedBusinessData,
  getSelectedBusinessIntegrationServiceData
} from "selectors/businesses";
import { checkModalContentShown } from "selectors/ui";
import { connect } from "react-redux";
import { getActiveOrganization } from "selectors/organizations";
import { getEnvVars } from "selectors/envVars";
import { getTextsData } from "selectors/texts";
import { getUserRestrictions } from "selectors/user";
import { v4 as uuid } from "uuid";
import AddDocumentWindow from "./lib/AddDocumentWindow";
import AddTagsWindow from "lib/windows/AddTagsWindow";
import Async from "utils/Async";
import ChangeStatusSelector from "lib/common/ChangeStatusSelector";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import Documents from "utils/Documents";
import DocumentsActions from "actions/DocumentsActions";
import DocumentsFilter from "lib/common/DocumentsFilter";
import DocumentsStatuses from "lib/pages/DocumentsPage/DocumentsStatuses";
import DocumentsTable from "./lib/DocumentsTable";
import DuplicatedDocumentsWindow from "./lib/DuplicatedDocumentsWindow";
import EditDocumentWindow from "./lib/EditDocumentWindow";
import Features from "const/Features";
import LinkContactWindow from "lib/windows/LinkContactWindow";
import MainApiActions from "actions/MainApiActions";
import MediaQuery from "react-responsive";
import PageActionsRow from "lib/common/PageActionsRow";
import PageHeader from "lib/common/PageHeader";
import React, { PureComponent } from "react";
import SelectPaymentAccountWindow from "lib/windows/SelectPaymentAccountWindow";
import StatusFilter from "lib/common/StatusFilter";
import TasksBlock from "nlib/common/TasksBlock";
import UiActions from "actions/UiActions";
import Utils from "utils/Utils";
import ValidationSchemes from "const/ValidationSchemes";
import moment from "moment";

const mapStateToProps = (state) => ({
  modalContentShown: checkModalContentShown(state),
  fetchingData: checkDocumentsFetching(state) || checkMatchesFetching(state),
  envVars: getEnvVars(state),
  documentsStats: getDocumentsStats(state),
  documentsData: getDocumentsData(state),
  documentsCurrentUploadCount: getUploadingDocumentsCount(state),
  selectedBusinessData: getSelectedBusinessData(state),
  selectedBusinessBookCloseDate: getSelectedBusinessBookCloseDate(state),
  selectedBusinessIntegrationServiceData: getSelectedBusinessIntegrationServiceData(state),
  activeOrganization: getActiveOrganization(state),
  userRestrictions: getUserRestrictions(state),
  textsData: getTextsData(state)
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (...args) => dispatch(UiActions.showModal(...args)),
  fetchAttachmentUrl: (...args) => dispatch(MainApiActions.fetchAttachmentUrl(...args)),
  fetchDocumentsList: (...args) => dispatch(DocumentsActions.fetchDocumentsList(...args)),
  addNewDocument: (...args) => dispatch(DocumentsActions.addNewDocument(...args)),
  importCloudDocuments: (...args) => dispatch(DocumentsActions.importCloudDocuments(...args)),
  uploadDocuments: (...args) => dispatch(DocumentsActions.uploadDocuments(...args)),
  editDocument: (...args) => dispatch(DocumentsActions.editDocument(...args)),
  deleteDocument: (...args) => dispatch(DocumentsActions.deleteDocument(...args)),
  bulkDocumentsUpdate: (...args) => dispatch(DocumentsActions.bulkDocumentsUpdate(...args))
});

@connect(mapStateToProps, mapDispatchToProps)
class DocumentsPage extends PureComponent {
  static ADD_DOCUMENT_ACTIONS = {
    IMPORT: "import",
    CREATE: "create"
  };

  editDocumentWindowUniqueId = null;

  dataListUpdateTimeoutId = null;

  willBeUnmounted = false;

  constructor(props) {
    super(props);

    let { envVars: { editItem, status, fromDate, toDate, text, paymentType, paidWith, createdBy, createdAt } } = this.props;

    const [editableDocumentId, editableDocumentTab] = editItem && editItem.split ? editItem.split(".") : [null, null];

    if (editableDocumentId && !text) text = editableDocumentId;

    const validDateRange = moment(fromDate).isValid() && moment(toDate).isValid();

    status = status && (DocumentsStatuses.getStatusData(status) ? status : undefined);
    this.state = {
      statusFilter: status === undefined ? null : status,
      filters: {
        fromDate: validDateRange ? fromDate : undefined,
        toDate: validDateRange ? toDate : undefined,
        text,
        paymentType,
        paidWith,
        createdBy,
        createdAt
      },
      sortings: null,
      selectedItems: [],
      readyToApproveIds: [],
      tablePage: 0,
      tablePages: 0,
      tablePageSize: Constants.TABLE_PAGE_SIZE,
      addDocumentWindowOpened: false,
      editDocumentWindowOpened: !!editableDocumentId,
      duplicatedDocumentsWindowOpened: false,
      addTagsWindowOpened: false,
      linkContactWindowOpened: false,
      editableDocumentData: editableDocumentId
        ? { id: editableDocumentId, initialTab: editableDocumentTab }
        : null,
      duplicatedDocumentId: null
    };
    this.editDocumentWindowUniqueId = uuid();
  }

  async getDocumentIdsReadyToApprove() {
    const { documentsData } = this.props;

    const result = await Async.runInSequence(documentsData.map((documentData) => {
      return async() => {
        return (documentData.status === DataConstants.STATUSES.TO_REVIEW)
          && (await this.checkDocumentDataValidityAsync(documentData))
          && documentData.id;
      };
    }));

    return result.filter(Boolean);
  }

  getDocumentDataById(documentId) {
    return this.props.documentsData.find(({ id }) => id === documentId);
  }

  getDataRequestOffset(leaveCurrent = false) {
    const { tablePage, tablePages, tablePageSize } = this.state;

    if (!leaveCurrent && tablePage === tablePages - 1) return (tablePage > 0 ? tablePage - 1 : 0) * tablePageSize;

    return tablePage * tablePageSize;
  }

  getStatusesList() {
    const { STATUSES: { EXPORTED } } = DataConstants;

    const { textsData: { uiTexts }, activeOrganization, selectedBusinessData } = this.props;

    const { extraData: { integrationService } = {} } = selectedBusinessData;

    const systemSpecificExportLabelId = integrationService && {
      xero: "atXero",
      quickBooks: "atQuickBooks"
    }[integrationService];

    return Object.values(DocumentsStatuses.getStatusData(undefined, activeOrganization.countryCode)).map((statusData) => {
      return systemSpecificExportLabelId && uiTexts[systemSpecificExportLabelId] && statusData.value === EXPORTED
        ? { ...statusData, labelLangId: systemSpecificExportLabelId }
        : statusData;
    });
  }

  checkDocumentAmountDataConsistence(documentData) {
    const {
      selectedBusinessData: { vatPayer },
      activeOrganization: { countryCode }
    } = this.props;

    return Documents.checkAmountDataConsistence({ documentData, countryCode, vatPayer });
  }

  async checkDocumentDataValidityAsync(documentData) {
    const { enabledButtons, selectedEditTab, files, ...restData } = documentData;

    const {
      DOCUMENT_VALIDATION_SCHEMA,
      RECEIPT_VALIDATION_SCHEMA
    } = ValidationSchemes;

    const validationSchema = restData.type === DataConstants.DOCUMENT_TYPES.RECEIPT
      ? RECEIPT_VALIDATION_SCHEMA
      : DOCUMENT_VALIDATION_SCHEMA;

    const dataCheck = this.checkDocumentAmountDataConsistence(documentData)
      && (await validationSchema.isValid(restData, { context: restData }));

    return dataCheck;
  }

  openDuplicatedDocumentsWindow(documentId) {
    this.setState({ duplicatedDocumentsWindowOpened: true, duplicatedDocumentId: documentId });
  }

  openEditDocumentWindow(documentId, initialTab = null) {
    this.setState(
      { editDocumentWindowOpened: true, editableDocumentData: { id: documentId, initialTab } },
      () => {
        const { envVars, history } = this.props;

        history.replace(
          `?${Utils.objectToQueryString({ ...envVars, editItem: `${documentId}${initialTab ? `.${initialTab}` : ""}` })}`
        );
      }
    );
  }

  closeEditDocumentWindow() {
    return new Promise((resolve) => {
      let { filters } = this.state;

      const { envVars, history } = this.props;

      const { editItem, ...restEnvVars } = envVars;

      const singleFiltered = editItem && editItem.split(".")[0] === filters.text;

      if (singleFiltered) {
        filters = { ...filters, text: singleFiltered ? "" : filters.text };
        delete restEnvVars.text;
      }

      this.setState(
        {
          filters,
          editDocumentWindowOpened: false,
          editableDocumentData: null,
          editDataAfterClose: null
        },
        () => {
          history.replace(`?${Utils.objectToQueryString(restEnvVars)}`);
          if (singleFiltered) this.resetDocumentsPage(true);
          resolve(singleFiltered);
        }
      );
    });
  }

  async deleteDocument(documentId) {
    const { textsData: { messages }, documentsData, showModal, deleteDocument } = this.props;

    const result = await showModal(messages.documentDeleteConfirm, null, true);

    if (result) {
      const successful = await deleteDocument(documentId);

      if (successful) this.initDocumentsPage(this.getDataRequestOffset(documentsData.length > 1), false);
    }
  }

  @bind
  async initDocumentsPage(dataOffset = 0, clearList = true, backgroundUpdate = false) {
    const { fetchDocumentsList } = this.props;

    const { filters, sortings, statusFilter, tablePageSize } = this.state;

    clearTimeout(this.dataListUpdateTimeoutId);
    await fetchDocumentsList(clearList, backgroundUpdate, statusFilter, filters, sortings, dataOffset, tablePageSize);
    clearTimeout(this.dataListUpdateTimeoutId);
    if (!this.willBeUnmounted) {
      this.dataListUpdateTimeoutId = setTimeout(
        () => this.initDocumentsPage(dataOffset, false, true),
        Constants.DATA_LIST_UPDATE_INTERVAL
      );
    }
  }

  resetDocumentsPage(clearList = true) {
    this.initDocumentsPage(0, clearList);
    this.setState({ tablePage: 0 });
  }

  bulkDocumentsUpdate(documentsIds, data) {
    const { documentsData, bulkDocumentsUpdate } = this.props;

    bulkDocumentsUpdate({ ids: documentsIds, data })
      .then((result) => {
        const { successCount: updatedDocumentsCount } = result || {};

        if (updatedDocumentsCount) {
          const statusChangedCount = data.status || !Object.values(data).length ? updatedDocumentsCount : 0;

          const offset = this.getDataRequestOffset(documentsData.length - statusChangedCount > 0);

          this.initDocumentsPage(offset, false);
        }
      });
  }

  bulkUpdateSelectedDocuments(data = {}) {
    const { selectedItems } = this.state;

    const { status } = data;

    const documentsIds = selectedItems.filter((id) => {
      const documentData = this.getDocumentDataById(id);

      if (status) {
        const { canBeAssigned } = DocumentsStatuses.getStatusData(data.status);

        return canBeAssigned && documentData.status !== data.status;
      }

      return true;
    });

    if (documentsIds.length) {
      this.bulkDocumentsUpdate(documentsIds, data);
    }
  }

  @bind
  bulkDocumentApprove(documentsIds) {
    const {
      showModal,
      documentsData,
      textsData: { uiTexts, messages },
      selectedBusinessBookCloseDate,
      selectedBusinessIntegrationServiceData
    } = this.props;

    const documentsReadyToApprove = documentsData.filter(({ id }) => documentsIds.includes(id));

    const rejectedDocuments = selectedBusinessBookCloseDate ? documentsReadyToApprove.filter(({ issueDate }) => {
      return issueDate && moment.utc(issueDate).isSameOrBefore(moment.utc(selectedBusinessBookCloseDate));
    }) : [];

    if (rejectedDocuments.length) {
      const closedBookTimeText = moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT);

      showModal(
        Utils.replaceTextVars(messages.closeBookWarningForDocs, {
          date: closedBookTimeText,
          service: selectedBusinessIntegrationServiceData.label
        }),
        uiTexts.warning
      );

      return;
    }

    const ids = documentsReadyToApprove.map(({ id }) => id);

    this.bulkDocumentsUpdate(ids, { status: DataConstants.STATUSES.TO_REPORT });
  }

  componentDidMount() {
    this.resetDocumentsPage();
    (async() => {
      const readyToApproveIds = await this.getDocumentIdsReadyToApprove();

      this.setState((prevState) => ({
        readyToApproveIds: prevState.readyToApproveIds.toString() === readyToApproveIds.toString()
          ? prevState.readyToApproveIds
          : readyToApproveIds
      }));
    })();
  }

  componentDidUpdate(prevProps) {
    const { editDocumentWindowOpened, editableDocumentData, selectedItems } = this.state;

    const { fetchingData, documentsData } = this.props;

    const { documentsData: prevDocumentsData } = prevProps;

    if (editDocumentWindowOpened && documentsData !== prevDocumentsData) {
      if (!fetchingData && !this.getDocumentDataById(editableDocumentData.id)) {
        this.closeEditDocumentWindow();
      }
    }

    const existedSelectedItems = selectedItems.filter((id) => documentsData.find((item) => item.id === id));

    if (selectedItems.length > existedSelectedItems.length) {
      this.setState({ selectedItems: existedSelectedItems });
    }
    (async() => {
      if (prevProps.documentsData === documentsData) return;

      const readyToApproveIds = await this.getDocumentIdsReadyToApprove();

      this.setState((prevState) => ({
        readyToApproveIds: prevState.readyToApproveIds.toString() === readyToApproveIds.toString()
          ? prevState.readyToApproveIds
          : readyToApproveIds
      }));
    })();
  }

  componentWillUnmount() {
    this.willBeUnmounted = true;
    clearTimeout(this.dataListUpdateTimeoutId);
  }

  @bind
  handleStatusFilterChange(statusFilter) {
    const { envVars, history } = this.props;

    this.setState({ statusFilter }, () => {
      history.replace(`?${Utils.objectToQueryString({ ...envVars, status: statusFilter })}`);
      this.resetDocumentsPage(false);
    });
  }

  @bind
  handleDocumentsFilterChange(filters) {
    const { envVars: { status }, history } = this.props;

    this.setState({ filters }, () => {
      history.replace(`?${Utils.objectToQueryString({ ...filters, status })}`);
      this.resetDocumentsPage(false);
    });
  }

  @bind
  handleEmailButtonClick() {
    const { textsData: { uiTexts, messages }, selectedBusinessData, showModal } = this.props;

    const [descriptionA, descriptionB, descriptionC] = messages.copyDocumentsEmailDescription;

    showModal(
      `${descriptionA}\n\n${descriptionB}\n\n${descriptionC}:\n\n${selectedBusinessData.meta.emailAddress}`,
      uiTexts.copyEmail,
      true,
      null,
      uiTexts.copyEmail
    ).then((result) => {
      if (result) {
        const { clipboard } = window.navigator;

        if (clipboard && clipboard.writeText) clipboard.writeText(selectedBusinessData.meta.emailAddress);
      }
    });
  }

  @bind
  handleChangeStatusSelectorChange(status) {
    const { textsData: { messages, uiTexts }, showModal } = this.props;

    const changeExportedStatusConfirm = this.state.selectedItems.some((documentId) => {
      return this.getDocumentDataById(documentId).status === DataConstants.STATUSES.EXPORTED;
    });

    if (changeExportedStatusConfirm) {
      showModal(messages.changeExportedStatusConfirm, uiTexts.confirm, true, "sm").then((modalResult) => {
        if (modalResult) this.bulkUpdateSelectedDocuments({ status });
      });
    } else this.bulkUpdateSelectedDocuments({ status });
  }

  @bind
  handleBulkDocumentsAddTags() {
    this.setState({ addTagsWindowOpened: true });
  }

  @bind
  handleBulkDocumentsLinkContact() {
    this.setState({ linkContactWindowOpened: true });
  }

  @bind
  handleBulkDocumentsDelete() {
    const { textsData: { messages }, showModal } = this.props;

    showModal(
      Utils.replaceTextVars(
        messages.bulkDocumentsDeleteConfirm,
        { documentsCount: this.state.selectedItems.length }
      ),
      null,
      true
    ).then((result) => {
      if (result) this.bulkUpdateSelectedDocuments();
    });
  }

  @bind
  handleDocumentsUnselect() {
    this.setState({ selectedItems: [] });
  }

  @bind
  handleTableFetchData({ page: tablePage, pages: tablePages, pageSize: tablePageSize, sorted }) {
    const [sortings = null] = sorted;

    const sortingsChanged = !Utils.checkDeepEquality(sortings, this.state.sortings);

    if (tablePage === tablePages) --tablePage;
    if (tablePage !== this.state.tablePage || tablePageSize !== this.state.tablePageSize || sortingsChanged) {
      this.setState({ tablePage, tablePageSize, sortings }, () => {
        this.initDocumentsPage(this.getDataRequestOffset(true), false);
      });
    }
    if (tablePages !== this.state.tablePages) this.setState({ tablePages });
  }

  @bind
  handleTableItemsSelect(selectedItems) {
    this.setState({ selectedItems });
  }

  @bind
  handleTableDocumentDelete(documentId) {
    this.deleteDocument(documentId);
  }

  @bind
  handleTableDocumentEdit(documentId, initialTab) {
    this.openEditDocumentWindow(documentId, initialTab);
  }

  @bind
  handleTableDocumentManageDuplicates(documentId) {
    this.openDuplicatedDocumentsWindow(documentId);
  }

  @bind
  handleImportDocumentsButtonClick() {
    this.setState({ addDocumentWindowOpened: true });
  }

  @bind
  handleEditDocumentWindowDocumentSwitch(documentId) {
    const { duplicatedDocumentsIds } = this.getDocumentDataById(documentId);

    if (duplicatedDocumentsIds && duplicatedDocumentsIds.length) this.openDuplicatedDocumentsWindow(documentId);
    else this.openEditDocumentWindow(documentId);
  }

  @bind
  handleDuplicatedDocumentsWindowClose({ duplicatedDocumentsIds, deleteDocument = false }) {
    const { duplicatedDocumentId } = this.state;

    if (duplicatedDocumentsIds) {
      this.setState({ editDataAfterClose: { duplicatedDocumentsIds } }, () => {
        this.openEditDocumentWindow(duplicatedDocumentId, null);
      });
    } else if (deleteDocument) this.deleteDocument(duplicatedDocumentId);
    this.setState({ duplicatedDocumentsWindowOpened: false, duplicatedDocumentId: null });
  }

  @bind
  async handleEditDocumentWindowClose(result, toReport, toExclude) {
    const { textsData: { uiTexts, messages }, editDocument, documentsData, showModal } = this.props;

    const { editableDocumentData: { id: documentId }, editDataAfterClose } = this.state;

    if (result) {
      if (toReport) {
        const {
          selectedBusinessBookCloseDate,
          selectedBusinessIntegrationServiceData
        } = this.props;

        if (selectedBusinessBookCloseDate && result.issueDate
          && moment.utc(result.issueDate).isSameOrBefore(moment.utc(selectedBusinessBookCloseDate))) {
          const closedBookTimeText = moment.utc(selectedBusinessBookCloseDate).format(Constants.DATETIME_FORMATS.DATE_TEXT_EXT);

          showModal(
            Utils.replaceTextVars(messages.closeBookWarningForDocs, {
              date: closedBookTimeText,
              service: selectedBusinessIntegrationServiceData.label
            }),
            uiTexts.warning
          );

          return;
        }
      }

      if (toExclude && this.getDocumentDataById(documentId).status === DataConstants.STATUSES.EXPORTED) {
        const modalResult = await showModal(messages.changeExportedStatusConfirm, uiTexts.confirm, true, "sm");

        if (!modalResult) return;
      }

      const { id, files, ...restResult } = result;

      await editDocument(documentId, { ...restResult, ...editDataAfterClose, files }, toReport, false, toExclude);
    } else if (editDataAfterClose) await editDocument(documentId, editDataAfterClose, false, true);

    this.closeEditDocumentWindow().then((pageReset) => {
      if (result) {
        const documentIndex = documentsData.findIndex(({ id }) => id === documentId);

        const { status: documentStatus } = this.getDocumentDataById(documentId) || {};

        if (~documentIndex) {
          const {
            id: nextDocumentId,
            status: nextDocumentStatus,
            duplicatedDocumentsIds: nextDuplicatedDocumentsIds
          } = (documentsData[documentIndex + 1] || documentsData[0] || {});

          if (nextDocumentId && nextDocumentId !== documentId && documentStatus === nextDocumentStatus) {
            if (nextDuplicatedDocumentsIds && nextDuplicatedDocumentsIds.length) {
              this.openDuplicatedDocumentsWindow(nextDocumentId);
            } else this.openEditDocumentWindow(nextDocumentId);
          }
        }
      }

      const { editDocumentWindowOpened } = this.state;

      if (!pageReset) this.initDocumentsPage(this.getDataRequestOffset(true), false, !result || editDocumentWindowOpened);
    });
  }

  @bind
  handleAddDocumentWindowClose(result, toReport) {
    if (result) {
      const { addNewDocument, importCloudDocuments, uploadDocuments } = this.props;

      (async() => {
        let successful = true;

        if (result.cloudService) {
          switch (result.cloudService) {
            case "gdrive":
              successful = await importCloudDocuments(
                result.cloudService,
                result.type,
                result.paymentType,
                result.googleDocs.map(({ id, name, mimeType }) => ({
                  fileId: id,
                  fileName: name,
                  fileMimeType: mimeType,
                  oAuthToken: result.oAuthToken
                })),
                result.merge
              );
              break;
            case "dropbox":
              successful = await importCloudDocuments(
                result.cloudService,
                result.type,
                result.paymentType,
                result.filesLinks.map(({ link, name, mimeType }) => ({
                  fileLink: link,
                  fileName: name,
                  fileMimeType: mimeType
                })),
                result.merge
              );
              break;
          }
        } else if (result.files) {
          successful = await uploadDocuments(
            result.files,
            {
              type: result.type,
              merge: result.merge,
              ...(result.paymentType ? { paymentType: result.paymentType } : {})
            },
            true
          );
        } else successful = await addNewDocument(result, toReport);
        if (successful) this.initDocumentsPage(this.getDataRequestOffset(true), false, true);
      })();
    }
    this.setState({ addDocumentWindowOpened: false });
  }

  @bind
  handleAddTagsWindowClose(tags) {
    if (tags) this.bulkUpdateSelectedDocuments({ tags });
    this.setState({ addTagsWindowOpened: false });
  }

  @bind
  handleSelectPaymentAccountWindowClose(result) {
    if (result) this.bulkUpdateSelectedDocuments({ serviceAccountId: result });
    this.setState({ selectPaymentAccountWindowOpened: false });
  }

  @bind
  handleLinkContactWindowClose(vendorId) {
    if (vendorId) this.bulkUpdateSelectedDocuments({ vendorId });
    this.setState({ linkContactWindowOpened: false });
  }

  @bind
  handleDocumentApprove(documentId) {
    this.bulkDocumentApprove([documentId]);
  }

  renderNoDataContent() {
    const {
      textsData: { uiTexts },
      activeOrganization,
      fetchingData,
      documentsCurrentUploadCount
    } = this.props;

    const { statusFilter } = this.state;

    const statusLabel = statusFilter && uiTexts[
      DocumentsStatuses.getStatusData(statusFilter, activeOrganization.countryCode).labelLangId
    ].toLowerCase();

    if (fetchingData) return <Preloader />;

    return (
      <div className={CommonCss.noDataContent}>
        <div>
          <div><FiFileText /></div>
          <div>
            {statusLabel
              ? Utils.replaceTextVars(uiTexts.noStatusDocuments, { status: statusLabel })
              : uiTexts.noDocuments}
          </div>
          <div>
            <Button
              size="sm"
              disabled={fetchingData || !!documentsCurrentUploadCount}
              onClick={this.handleImportDocumentsButtonClick}>
              <span>
                {documentsCurrentUploadCount ? <FiLoader data-wait /> : <FiPlusCircle />}
                <span>{uiTexts.importDocuments}</span>
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { TO_REVIEW } = DataConstants.STATUSES;

    const {
      textsData: { uiTexts },
      selectedBusinessData,
      modalContentShown,
      userRestrictions,
      activeOrganization: { pricingPlan },
      documentsData,
      documentsStats,
      documentsCurrentUploadCount,
      fetchingData
    } = this.props;

    let {
      statusFilter,
      filters,
      selectedItems,
      readyToApproveIds,
      tablePage,
      tablePageSize,
      addDocumentWindowOpened,
      editDocumentWindowOpened,
      duplicatedDocumentsWindowOpened,
      addTagsWindowOpened,
      selectPaymentAccountWindowOpened,
      linkContactWindowOpened,
      editableDocumentData,
      duplicatedDocumentId
    } = this.state;

    const bulkEditActionsDisabled = statusFilter && statusFilter !== TO_REVIEW;

    const totalDataCount = documentsStats.current || 0;

    const editableDocument = editableDocumentData && this.getDocumentDataById(editableDocumentData.id);

    if (editableDocument) {
      const {
        payTo = {},
        recogniseData = {},
        ...resetDocumentData
      } = editableDocument;

      editableDocumentData = {
        ...editableDocumentData,
        recogniseData,
        editData: {
          ...resetDocumentData,
          payTo: { ...payTo, bankName: payTo.bankCode }
        }
      };
    }

    return (
      <Page className={Css.documentsPage}>
        <TasksBlock className={CommonCss.tasksBlock} />
        <PageHeader>
          <div className={Css.buttonsContainer}>
            {Features.checkIsAllowed(pricingPlan, Features.DOCUMENTS_INBOX)
              && selectedBusinessData.meta.emailAddress && (
              <div>
                <Button
                  size="sm"
                  theme="info"
                  disabled={fetchingData}
                  onClick={this.handleEmailButtonClick}>
                  <FiAtSign />
                  <span>{uiTexts.emailInboxForDocuments}</span>
                </Button>
              </div>
            )}
            {Features.checkIsAllowed(pricingPlan, Features.DOCUMENTS_IMPORT)
              && !!documentsData.length
              && (
                <div>
                  <Button
                    size="sm"
                    disabled={fetchingData || !!documentsCurrentUploadCount}
                    onClick={this.handleImportDocumentsButtonClick}>
                    <span>
                      {documentsCurrentUploadCount ? <FiLoader data-wait /> : <FiPlusCircle />}
                      <span>{uiTexts.importDocuments}</span>
                    </span>
                  </Button>
                </div>
              )}
          </div>
        </PageHeader>
        <Card>
          <CardHeader className={Css.cardHeader}>
            <div>
              <StatusFilter
                statusesList={this.getStatusesList()}
                stats={documentsStats}
                value={statusFilter}
                disabled={fetchingData}
                onChange={this.handleStatusFilterChange} />
            </div>
          </CardHeader>
          <CardBody className={Css.cardBody}>
            <PageActionsRow sticky={Object.values(filters).filter(Boolean).length || selectedItems.length}>
              <DocumentsFilter
                key={Object.values(filters).join()}
                initialValue={filters}
                disabled={fetchingData}
                onChange={this.handleDocumentsFilterChange} />
              {!!(documentsData.length && selectedItems.length) && <>
                <div><span>{uiTexts.selected}: </span><b>{selectedItems.length}</b></div>
                <ChangeStatusSelector
                  statusesList={this.getStatusesList()}
                  excludedStatuses={[
                    statusFilter,
                    userRestrictions.documentsUpdate && TO_REVIEW
                  ]}
                  disabled={fetchingData}
                  onChange={this.handleChangeStatusSelectorChange} />
                <ActionsButtons
                  className={Css.actionsButtons}
                  disabled={fetchingData}
                  disabledAddTags={bulkEditActionsDisabled}
                  disabledLinkContact={bulkEditActionsDisabled}
                  disabledDelete={userRestrictions.documentsDelete}
                  onAddTags={this.handleBulkDocumentsAddTags}
                  onLinkContact={this.handleBulkDocumentsLinkContact}
                  onDelete={this.handleBulkDocumentsDelete} />
                <ActionsButtons
                  className={Css.actionsButtons}
                  onUnselect={this.handleDocumentsUnselect} />
              </>}
            </PageActionsRow>
            {documentsData.length
              ? (<MediaQuery maxWidth={Constants.SIMPLIFIED_LAYOUT_MAX_WIDTH}>
                {(simplifiedLayoutMatches) =>
                  (<DocumentsTable
                    businessData={selectedBusinessData}
                    data={documentsData}
                    documentsReadyToApproveIds={readyToApproveIds}
                    page={tablePage}
                    pageSize={tablePageSize}
                    totalDataCount={totalDataCount}
                    selectedItems={selectedItems}
                    disabled={fetchingData}
                    shouldUpdate={!modalContentShown}
                    simplifiedLayout={!!simplifiedLayoutMatches}
                    bulkDocumentApprove={this.bulkDocumentApprove}
                    onFetchData={this.handleTableFetchData}
                    onItemsSelect={this.handleTableItemsSelect}
                    onDocumentEdit={this.handleTableDocumentEdit}
                    onDocumentApprove={this.handleDocumentApprove}
                    onDocumentDelete={this.handleTableDocumentDelete}
                    onDocumentManageDuplicates={this.handleTableDocumentManageDuplicates} />)}
              </MediaQuery>)
              : this.renderNoDataContent()}
          </CardBody>
          {addDocumentWindowOpened && (
            <AddDocumentWindow onClose={this.handleAddDocumentWindowClose} />
          )}
          {editDocumentWindowOpened && editableDocument && (
            <EditDocumentWindow
              {...editableDocumentData}
              key={`${editableDocumentData.id}:${this.editDocumentWindowUniqueId}`}
              onDocumentSwitch={this.handleEditDocumentWindowDocumentSwitch}
              onClose={this.handleEditDocumentWindowClose} />
          )}
          {duplicatedDocumentsWindowOpened && (
            <DuplicatedDocumentsWindow
              disabled={fetchingData}
              documentData={this.getDocumentDataById(duplicatedDocumentId)}
              onClose={this.handleDuplicatedDocumentsWindowClose} />
          )}
          {selectPaymentAccountWindowOpened
            && <SelectPaymentAccountWindow onClose={this.handleSelectPaymentAccountWindowClose} />}
          {addTagsWindowOpened && <AddTagsWindow onClose={this.handleAddTagsWindowClose} />}
          {linkContactWindowOpened && <LinkContactWindow onClose={this.handleLinkContactWindowClose} />}
        </Card>
      </Page>
    );
  }
}

export default DocumentsPage;
